// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Components
import { disablePageScroll, enablePageScroll, addFillGapTarget, addFillGapSelector, setFillGapMethod } from 'scroll-lock';
import Swiper, { Pagination, Navigation, Autoplay, FreeMode, EffectFade } from 'swiper';


document.addEventListener('DOMContentLoaded', function() {
	let mm = gsap.matchMedia();
	var r = document.querySelector(':root');
	var body = document.body;

	// HELP CHECK
	let viewportWidthEl = document.getElementById('viewport-width');

	function viewportCalc() {
		//let viewportHeight = window.innerHeight;
		let viewportWidth = window.innerWidth;
		//console.log(viewportWidth);
		//console.log(viewportHeight);

		if(window.innerWidth < 576){
			viewportWidthEl.style.backgroundColor = "red";
            viewportWidthEl.innerText = 'xs';
			//viewportHeightEl.style.backgroundColor = "red";

		} else if(window.innerWidth < 768) {
			viewportWidthEl.style.backgroundColor = "orange";
            viewportWidthEl.innerText = 'sm';

			//viewportHeightEl.style.backgroundColor = "purple";

		} else if(window.innerWidth < 992) {
			viewportWidthEl.style.backgroundColor = "green";
            viewportWidthEl.innerText = 'md';
			//viewportHeightEl.style.backgroundColor = "blue";

		} else if(window.innerWidth < 1200) {
			viewportWidthEl.style.backgroundColor = "blue";
            viewportWidthEl.innerText = 'lg';
			//viewportHeightEl.style.backgroundColor = "green";

		} else if(window.innerWidth < 1400) {
			viewportWidthEl.style.backgroundColor = "purple";
            viewportWidthEl.innerText = 'xl';
			//viewportHeightEl.style.backgroundColor = "orange";
		}

		else {
			viewportWidthEl.style.backgroundColor = "black";
            viewportWidthEl.innerText = 'xxl';
			//viewportHeightEl.style.backgroundColor = "black";
		}
	}

	//viewportCalc();

	// RESIZE
	window.addEventListener("resize", function(){
		//viewportCalc();
	});

	// FOOTER PIC HEIGHT CALC
	var bannerFooterImg = document.getElementById('bannerFooterImg');

	function bannerHomepageImgHeight_set() {
		var bannerFooterImgHeight = bannerFooterImg.offsetHeight;
		//console.log(bannerFooterImgHeight);
		r.style.setProperty('--banner-height', bannerFooterImgHeight + 'px');
	}

	bannerHomepageImgHeight_set();

	// RESIZE
	window.addEventListener("resize", function(){
		bannerHomepageImgHeight_set();
	});


	// PARALLAX
	gsap.utils.toArray("[data-speed]").forEach(el => {
		gsap.to(el, {
			y: function() {return (1 - parseFloat(el.getAttribute("data-speed"))) * (ScrollTrigger.maxScroll(window) - (this.scrollTrigger ? this.scrollTrigger.start : 0))},
			ease: "none",
			scrollTrigger: {
				trigger: el,
				start: "top 78%",
				end: "max",
				invalidateOnRefresh: true,
				scrub: true
			}
		});
	});

	// APPEAR ANIMATION
	var testSectionIntro = document.querySelector('.section.--intro');
	if(testSectionIntro) {
		var sectionIntroTL = gsap.timeline({
			delay: 0.1,
		});
		
		sectionIntroTL.to('.section.--intro .title-container > *', {
			opacity:1,
			y:0,
			stagger: 0.1,
		});

		sectionIntroTL.to('.section.--intro .imgs-container > *', {
			opacity:1,
			stagger: 0.1,
		}, 0.5);

		sectionIntroTL.to('.section.--intro .map-container', {
			opacity:1,
		}, 0.5);

		sectionIntroTL.to('.section.--intro .contact-container > *', {
			opacity:1,
			y:0,
			stagger: 0.1,
		}, 0.75);	
	}

	var testSectionInfos = document.querySelector('.section.--infos');
	if(testSectionInfos) {
		var sectionInfosSecondTL = gsap.timeline({
			duration: .1,
		});

		sectionInfosSecondTL.pause();

		var sectionInfosTL = gsap.timeline({
			duration: .1,
			scrollTrigger: {
				trigger: testSectionInfos,
				start: "top 78%",
				toggleActions: "play none none none",
				//markers: true,
			}, 

			onComplete() {
				sectionInfosSecondTL.play();
			}
		});
	
		sectionInfosTL.pause();
	
		sectionInfosTL.fromTo('.section.--infos .main-title-container > *', {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
			stagger: 0.1,
		});
		
		sectionInfosTL.fromTo('.section.--infos .col-container .icons-container .arrow-container' , {
			opacity:0,
			y:-10,
		},{
			opacity:1,
			y:0,
		}, "<");

		sectionInfosTL.fromTo('.section.--infos .col-container .icons-container .icon-container' , {
			opacity:0,
		},{
			opacity:1,
		}, "<");

		sectionInfosTL.fromTo('.section.--infos .col-container .h3-title' , {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
		}, "<");

		sectionInfosTL.fromTo('.section.--infos .col-container .intro' , {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
		}, "<");

		sectionInfosTL.fromTo('.section.--infos .col-container .main-block' , {
			opacity:0,
		},{
			opacity:1,
		});

		sectionInfosTL.fromTo('html' , {
			"--opacity-var": "0",
		},{
			"--opacity-var": "1",
			duration: 0.075
		}, "<");

		sectionInfosSecondTL.fromTo('.section.--infos .col-container .infos-container h4' , {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
		});

		sectionInfosSecondTL.fromTo('.section.--infos .col-container .infos-container .icon-container' , {
			opacity:0,
		},{
			opacity:1,
		});

		sectionInfosSecondTL.fromTo('.section.--infos .col-container .infos-container .step-container' , {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
			stagger: 0.1
		});

		sectionInfosSecondTL.fromTo('.section.--infos .col-container .infos-container .agenda-main-container' , {
			opacity:0,
		},{
			opacity:1,
		});

		sectionInfosSecondTL.fromTo('.section.--infos .col-container .infos-container .note' , {
			opacity:0,
			y:10,
		},{
			y:0,
			opacity:1,
		});
	}

	var testSectionCommandes = document.querySelector('.section.--commandes');
	if(testSectionCommandes) {
		var sectionCommandesTL = gsap.timeline({
			duration: .2,
			scrollTrigger: {
				trigger: testSectionCommandes,
				start: "top 78%",
				toggleActions: "play none none none",
				//markers: true,
			}, 
		});
	
		sectionCommandesTL.pause();
	
		sectionCommandesTL.fromTo('.section.--commandes h3', {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
		});

		mm.add("(max-width: 991px)", () => {
			sectionCommandesTL.fromTo('.section.--commandes .left .btn', {
				opacity:0,
				y:10,
			},{
				opacity:1,
				y:0,
				stagger: 0.1,
			});
	
			sectionCommandesTL.fromTo('.section.--commandes .right .btn', {
				opacity:0,
				y:10,
			},{
				opacity:1,
				y:0,
				stagger: 0.1,
			}, "<");
		});

		mm.add("(min-width: 992px)", () => {
			sectionCommandesTL.fromTo('.section.--commandes .left > *', {
				opacity:0,
				x:10,
			},{
				opacity:1,
				x:0,
				stagger: -0.1,
			});
	
			sectionCommandesTL.fromTo('.section.--commandes .right > *', {
				opacity:0,
				x:-10,
			},{
				opacity:1,
				x:0,
				stagger: 0.1,
			});
		});

		
		
	}

	var testSectionAbout = document.querySelector('.section.--about');
	if(testSectionAbout) {
		var sectionAboutTL = gsap.timeline({
			duration: .2,
			scrollTrigger: {
				trigger: testSectionAbout,
				start: "top 78%",
				toggleActions: "play none none none",
				//markers: true,
			}, 
		});
	
		sectionAboutTL.pause();
	
		sectionAboutTL.fromTo('.section.--about .main-block .img-container', {
			opacity:0,
		},{
			opacity:1,
		});

		sectionAboutTL.fromTo('.section.--about .main-block .body > *', {
			opacity:0,
			y:10,
		},{
			opacity:1,
			y:0,
			stagger: 0.1,
		});
	
	}

	var testSectionFaq = document.querySelector('.section.--faq');
	if(testSectionFaq) {
		var sectionFaqTL = gsap.timeline({
			duration: .2,
			scrollTrigger: {
				trigger: testSectionFaq,
				start: "top 78%",
				toggleActions: "play none none none",
				//markers: true,
			}, 
		});
	
		sectionFaqTL.pause();
	
		sectionFaqTL.fromTo('.section.--faq .title-container > *', {
			opacity:0,
			y:10,
		},{
			y:0,
			stagger:0.1,
			opacity:1,
		});

		sectionFaqTL.fromTo('.section.--faq .main-block .tab', {
			opacity:0,
			y:10,
		},{
			y:0,
			stagger:0.1,
			opacity:1,
		});
	}

	// HEADER TINY ON SCROLL
	mm.add("(min-width: 992px)", () => {
		ScrollTrigger.create({
			start: "top -100px",
			//scroller: scrollerBox,
			end: 99999,
			//markers: true,

			toggleClass: { targets: 'header', className: '--tiny' },
		});
	});

	mm.add("(max-width: 991px)", () => {
		ScrollTrigger.create({
			start: "top -30px",
			//scroller: scrollerBox,
			end: 99999,
			//markers: true,

			toggleClass: { targets: 'header', className: '--tiny' },
		});
	});

	// INFOS ACCORDION RESPONSIVE
	const colsContainer = document.querySelectorAll('.col-container');

	colsContainer.forEach(colContainer => {
		colContainer.querySelector('.accordion-btn').addEventListener('click', function() {
	
			if (colContainer.classList.contains('accordion-open')) { 
				//console.log('titi');
				gsap.to(window, {duration: 0, scrollTo: {y: colContainer, offsetY: 150}});
			}

			colContainer.classList.toggle('accordion-open');
		});
	});

	// INFOS COL INTRO HEIGHT CALC
	
	mm.add("(min-width: 768px)", () => {
		function colIntroHeight_set() {
			const colsIntro = document.querySelectorAll('.--infos .col-container .intro-inner');

			for (let i = 0; i < colsIntro.length; i++) {
				var testHighter = Math.max(colsIntro[0].offsetHeight, colsIntro[1].offsetHeight);
		
				if(testHighter != 0) {
					r.style.setProperty('--col-intro-height', testHighter + 'px');
				}
			}

			//console.log(testHighter);
		}

		colIntroHeight_set();

		// RESIZE
		window.addEventListener("resize", function(){
			colIntroHeight_set();
		});
	});
	

	// SCROLL TO SECTIONS
	const buttonsScrollSections = document.querySelectorAll('.scrollto-section');
	
	buttonsScrollSections.forEach(buttonScrollSections => {
		var anchorSection = buttonScrollSections.getAttribute('data-anchor');
		
		//console.log(anchorSection);

		buttonScrollSections.addEventListener('click', function() {
			gsap.to(window, {duration: 2, scrollTo: {y: anchorSection, offsetY: 200}});
		});
	});

	const buttonsScrollFAQ = document.querySelectorAll('.note');
	
	buttonsScrollFAQ.forEach(buttonScrollFAQ => {
		//var anchorSection = buttonScrollSections.getAttribute('data-anchor');
		
		//console.log(anchorSection);

		buttonScrollFAQ.addEventListener('click', function() {
			gsap.to(window, {duration: 2, scrollTo: {y: '#faq', offsetY: 200}});
		});
	});
})


